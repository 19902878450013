svg {
  /*transform: rotate(180deg);*/
  max-width: 95vw;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

@media screen and (min-width: 845px) {
  svg {
    max-width: 60vw;
  }
}

div.poetry {
  padding-top: 4em;
  background-color: #fff;
  text-align: center;
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}

.path {
  stroke-dasharray: 22000;
  stroke-dashoffset: 22000;
  animation: dash 7s linear forwards;
}

@keyframes dash {
  from {
    stroke-dashoffset: 22000;
  }
  to {
    stroke-dashoffset: 0;

  }
}

p.title {
  text-align: center;
  color: #696969;
  font-family: Garamond, serif;
  font-size: 2.3em;
  letter-spacing: 1px;
}

p.description {
  text-align: center;
  color: #696969;
  font-family: Garamond, serif;
  font-size: 1em;
  letter-spacing: 1px;
}

.poetryBtn {
  color: #BDCFE7;
  border-radius: 20px;
  padding: 10px 15px;
  background-color: transparent;
  border: 1px solid #BDCFE7;
  box-shadow: 0 0 10px #bfd8fa;
}
.poetryBtn:hover {
  color: #98b9e5;
  border: 1px solid #98b9e5;
  box-shadow: 0 0 10px #8eb9fc;
}

